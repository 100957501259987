<template>
  <svg
    viewBox="0 0 24 24">
    <path
      d="M12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24
      18.6274 18.6274 24 12 24ZM9.73046 15.5648L5.71732 11.4331L4.28268 12.8266L9.73046
      18.4352L19.7173 8.15345L18.2827 6.75996L9.73046 15.5648Z"
    />
  </svg>
</template>
